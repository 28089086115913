import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NotAuthenticated from "../components/MyPage/NotAuthenticated"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import VerifyUserContent from "../components/MyPage/VerifyUser"

const Verification = () => {
  const isAuthenticated = useIsAuthenticated()

  console.log(isAuthenticated)

  // if (isAuthenticated) {
  //   return <Redirect to="/hefinn" />
  // }

  return (
    <div>
      <VerifyUserContent />
    </div>
  )
}

export default Verification
